<div class="main-container mt-5 pb-6"
  *ngIf="!selectedCard">
  <div class="card-grid">
    <ng-container *ngFor="let card of cards">
      <div class="card">
        <ng-container>
          <img class="info"
            (click)="selectedCard = card"
            src="assets/circle-info-solid.svg"
            alt="info"/>
          <img alt="{{card.image}}"
            src="{{card.image}}"
            class="logo"/>
          <div class="categories">
            <div class="category">
              <span>Facilità d’uso</span>
              <div class="rating">
                <ng-container *ngFor="let star of card.facilita">
                  <img *ngIf="star"
                    src="assets/star-solid.svg"
                    class="star"
                    alt="star"/>
                  <img *ngIf="!star"
                    src="assets/star-regular.svg"
                    class="star"
                    alt="star"/>
                </ng-container>
              </div>
            </div>
            <div class="category">
              <span>Numero visite mensili</span>
              <div class="rating">
                <ng-container *ngFor="let star of card.visite">
                  <img *ngIf="star"
                    src="assets/star-solid.svg"
                    class="star"
                    alt="star"/>
                  <img *ngIf="!star"
                    src="assets/star-regular.svg"
                    class="star"
                    alt="star"/>
                </ng-container>
              </div>
            </div>
            <div class="category">
              <span>Sicurezza</span>
              <div class="rating">
                <ng-container *ngFor="let star of card.sicurezza">
                  <img *ngIf="star"
                    src="assets/star-solid.svg"
                    class="star"
                    alt="star"/>
                  <img *ngIf="!star"
                    src="assets/star-regular.svg"
                    class="star"
                    alt="star"/>
                </ng-container>
              </div>
            </div>
            <div class="category">
              <span>Servizio clienti</span>
              <div class="rating">
                <ng-container *ngFor="let star of card.clienti">
                  <img *ngIf="star"
                    src="assets/star-solid.svg"
                    class="star"
                    alt="star"/>
                  <img *ngIf="!star"
                    src="assets/star-regular.svg"
                    class="star"
                    alt="star"/>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="mb-4 mt-6 is-fullwidth has-text-centered is-size-3">
    <strong>Ciò che non è stato sprecato utilizzando Hannusato.it</strong>
  </div>

  <div class="categories-number-container mt-6">
    <div class="category-number">
      <div class="slogan mb-4 has-text-white has-text-centered">CO2 non emessi in atm (kg)</div>
      <img class="icon-numbers animate__animated animate__pulse animate__infinite"
        src="assets/factory.png"/>
      <div class="mt-4 thick-number">{{co2  | number: '1.0-2' : 'it'}}</div>
    </div>
    <div class="category-number">
      <img class="icon-numbers"
        src="assets/equals-solid.svg"/>
    </div>
    <div class="category-number">
      <div class="slogan mb-4 has-text-white has-text-centered">Produzione di pasta (kg)</div>
      <img class="icon-numbers animate__animated animate__pulse animate__infinite"
        src="assets/spaghetti.png"/>
      <div class="mt-4 thick-number">{{pasta  | number: '1.0-2' : 'it'}}</div>
    </div>
    <div class="category-number">
      <img class="icon-numbers"
        src="assets/equals-solid.svg"/>
    </div>
    <div class="category-number">
      <div class="slogan mb-4 has-text-white has-text-centered">Produzione bottiglie di Coca-Cola (200 ml)</div>
      <img class="icon-numbers animate__animated animate__pulse animate__infinite"
        src="assets/coke.png"/>
      <div class="mt-4 thick-number">{{bottles | number: '1.0-2' : 'it'}}</div>
    </div>
  </div>

  <div class="mt-6">
    Una complessa ricerca condotta dall’Istituto Svedese di Ricerca Ambientale (IVL) per il sito di compravendita dell’usato "Subito.it",
    ha stimato la CO2 emessa in atmosfera durante tutta “la vita” di un oggetto, dalla produzione delle materie prime ai costi dovuti all'utilizzo dello stesso, fino a quando diventerà un rifiuto.<br/>
    Per citare qualche esempio, IVL dice che uno scooter ‘pesa’ 190 kg di CO2, uno smartphone 47, una bicicletta 99 e un paio di scarpe 19.
    Quanta anidride carbonica si risparmia comprando, ad esempio, una maglietta usata? La risposta è 7,2 chili, ovvero la stessa quantità che sarebbe emessa dalla produzione di 11 chili di pasta oppure di circa 19 bottigliette di coca-cola da 200 ml.<br/>
    Con questo Counter qui sopra, abbiamo cercato di stimare quanto può essere risparmiato in termini di emissioni, anche passando attraverso hannusato.it.
  </div>
</div>

<div class="block primary-bg has-text-white last mt-4 footer"
  *ngIf="!selectedCard">

  <div class="mr-6 small-logo-container">
    <img class="is-fullwidth" src="assets/logo.png"/>
    <div class="is-flex is-justify-content-center mt-2">
      <img src="assets/instagram.png" class="insta-logo" />
    </div>
  </div>
  <div>Benvenuti su hannusato.it<br/>Il nostro sito nasce dall’esigenza di avere una visione più immediata di ciò che si può trovare nel mondo dell’usato online; quello che vi possiamo offrire è anche una panoramica che illustri i lati positivi/negativi (individuati grazie alla nostra esperienza)
    dei vari siti che trattano oggetti di seconda mano. Ancora molta gente, oggi, è diffidente nello scegliere “l’usato” piuttosto che il nuovo; tuttavia, in questo mondo si possono trovare moltissimi oggetti praticamente nuovi, che sono inutilizzati e possono fare le fortune di molti compratori.
    Questa però è solo la punta dell’Iceberg… attingere dal mondo dell’usato invece che dal “nuovo”, può contribuire, nel nostro piccolo, a combattere il consumismo esagerato e gli sprechi che derivano da esso.<br/> Ci auguriamo che anche voi, come noi, possiate sempre più dare nuova vita a oggetti
    che si ritrovano chiusi in un cassetto; e perché no, anche voi, ad HANNUSARE gli affari
  </div>
</div>

<div class="modal-container"
  *ngIf="selectedCard">
  <div class="modal-back"></div>
  <div class="custom-modal scale-in-center">
    <img class="close-modal"
      (click)="selectedCard = null"
      src="assets/xmark-solid.svg"
      alt="close"/>
    <div class="content-container content">
      <img class="modal-brand"
        alt="{{selectedCard.image}}"
        src="{{selectedCard.image}}"/>
      <div class="first-p">
        {{selectedCard.firstP}}
      </div>
      <div class="most-sold">
        <ul>
          <li *ngFor="let item of selectedCard.mostSold">
            {{item}}
          </li>
        </ul>
      </div>
      <div class="first-p is-underlined mb-4 mt-4">
        <b>Acquisto e spedizione</b>
      </div>
      <span>PRO:</span>
      <ul *ngIf="selectedCard.pros">
        <li *ngFor="let pro of selectedCard.pros">{{pro}}</li>
      </ul>
      <ul *ngIf="!selectedCard.cons">
        <li><i>N/A</i></li>
      </ul>
      <span>CONTRO:</span>
      <ul *ngIf="selectedCard.cons">
        <li *ngFor="let con of selectedCard.cons">{{con}}</li>
      </ul>
      <ul *ngIf="!selectedCard.cons">
        <li><i>N/A</i></li>
      </ul>
      <div class="first-p is-underlined mb-4 mt-4">
        <b>Metodi di pagamento consigliati</b>
      </div>
      <ul>
        <li *ngFor="let method of selectedCard.methods">
          {{method.title}}
          <ul *ngIf="method.subItems?.length > 0">
            <li *ngFor="let sub of method.subItems">{{sub}}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
