import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit {
  searchControl = new FormControl();

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  search(): void {
    if (this.searchControl.value && this.searchControl.value.trim() !== '') {
      void this.router.navigateByUrl(`/search/${this.searchControl.value}`);
    }
  }
}
