import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.scss' ],
})
export class HomeComponent implements OnInit {
  selectedCard;

  averageCO2 = 10.37142857142857;
  startingDate = new Date(2023, 6, 17);

  co2: string;
  pasta: string;
  bottles: string;

  cards = [
    {
      image: 'assets/vinted.png',
      facilita: [ true, true, true, true, false ],
      visite: [ true, true, true, false, false ],
      sicurezza: [ true, true, true, false, false ],
      clienti: [ true, true, true, true, false ],
      firstP: 'Facile ed intuitiva sia per vendere che per acquistare, l’interfaccia risulta molto semplice e dettagliata. I prodotti più venduti sono:',
      mostSold: [ 'Abbigliamento', 'Giochi e videogiochi', 'Libri', 'Oggetti da collezione' ],
      pros: [
        'No commissioni di vendita',
        'Presenza di assicurazione sui prodotti acquistati (spesa a carico acquirente = a 0.70cent + 5% prezzo prodotto)',
        'Presenza assistenza cliente online, rapida ed efficiente, tramite chat diretta con un assistente del team Vinted',
      ],
      cons: [
        'Non copre i resi: spese di spedizione reso a carico dell’acquirente (in Italia sono poco costose, all’estero sono più cospicue)',
      ],
      methods: [
        {
          title: 'Metodo pagamento con protezione acquisti Vinted (si paga con carta di credito/debito)',
        },
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti)',
          ],
        },
      ],
    },
    {
      image: 'assets/wallapop.png',
      facilita: [ true, true, true, true, true ],
      visite: [ true, true, true, true, false ],
      sicurezza: [ true, true, true, true, false ],
      clienti: [ true, true, true, false, false ],
      firstP: 'Facile ed intuitiva sia per vendere che per acquistare, l’interfaccia risulta molto semplice e dettagliata. I prodotti più venduti sono:',
      mostSold: [ 'Articoli per la casa', 'Abbigliamento', 'Smartphone ed Elettronica', 'Materiale sportivo (pesi, cyclette biciclette forniture da campeggio)', 'Libri', 'Strumenti musicali' ],
      pros: [
        'No commissioni di vendita',
        'Presenza di assicurazione sui prodotti acquistati e copertura dei resi (Wallapop Protect: costo intorno al 10% del prodotto acquistato, dipende da peso prodotto e prezzo pattuito)',
      ],
      cons: [
        'Assistenza cliente non intuitiva e poco reattiva, in quanto la comunicazione avviene esclusivamente tramite mail',
      ],
      methods: [
        {
          title: 'Metodo pagamento con protezione acquisti Wallapop Protect (si paga con carta di credito/debito)',
        },
        {
          title: 'Pagamento sul sito con ritiro di persona gestito dal sito (piccoli costi di gestione 0.5% costo articolo)',
        },
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti',
          ],
        },
      ],
    },
    {
      image: 'assets/ebay.png',
      facilita: [ true, true, false, false, false ],
      visite: [ true, true, true, true, true ],
      sicurezza: [ true, true, true, true, false ],
      clienti: [ true, true, true, true, true ],
      firstP: 'Interfaccia complicata e dispersiva per i neofiti. Ci sono due modi di acquistare un prodotto: tramite asta o “compro subito”; ciò rende le dinamiche di acquisto un po\' più complesse rispetto agli altri siti. Anche la ricerca ai servizi offerti dal sito, pur essendo molteplici ed efficaci, risulta poco immediata. I prodotti più venduti sono:',
      mostSold: [ 'Elettronica di consumo', 'Abbigliamento di marca', 'Oggetti da collezione', 'Prodotti per la casa e l’arredamento' ],
      pros: [
        'Presenza di assicurazione sui prodotti acquistati compresa nel prezzo di acquisto',
        'Copertura dei resi (Garanzia cliente Ebay)',
        'Assistenza clienti tramite chat o chiamata: La chat in tempo reale è disponibile 7 giorni su 7, dalle 9:00 alle 18:00 (esclusi i giorni festivi).',
      ],
      cons: [
        'Difficoltà nel trovare assistenza cliente tramite chat in tempo reale all’interno del sito',
        'Politica di reso complicata e restrittiva',
      ],
      methods: [
        {
          title: 'Metodo pagamento coperto dalla Garanzia cliente Ebay',
          subItems: [
            'Carta di credito/debito',
            'PayPal',
            'Apple Pay o Google Pay',
          ],
        },
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti)',
          ],
        },
      ],
    },
    {
      image: 'assets/subito.svg',
      facilita: [ true, true, true, true, false ],
      visite: [ true, true, true, false, false ],
      sicurezza: [ true, true, true, false, false ],
      clienti: [ true, true, true, true, false ],
      firstP: 'Molto facile ed intuitiva, sia per contattare i venditori che per effettuare gli acquisti; un neofita in genere non ha alcun tipo di problema. Le spedizioni e gli annunci sono relativi solo all’Italia; nonostante ciò, il sito vanta il numero più alto di visite mensili in Italia. I prodotti che si possono vendere su subito.it sono:',
      mostSold: [ 'Elettronica di consumo', 'Prodotti di sport/abbigliamento', 'Auto/moto', 'Appartamenti' ],
      pros: [
        'No commissioni di vendita',
        'Presenza di assicurazione sui prodotti acquistati tramite servizio tuttosubito (spesa a carico acquirente = 0,50 Euro sommati al 4% del prezzo di acquisto concordato tra l\'Acquirente e il Venditore)',
        'Possibilità di reso compresa nel servizio',
      ],
      cons: [
        'Assistenza cliente non intuitiva e poco reattiva, solitamente viene contattata solo tramite mail',
      ],
      methods: [
        {
          title: 'Metodo pagamento coperto dal servizio tuttosubito',
          subItems: [
            'Carta di credito/debito',
            'PayPal (possibilità pagamento in tre rate)',
            'Apple Pay o Google Pay',
          ],
        },
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti',
          ],
        },
      ],
    },
    {
      image: 'assets/bakeca.png',
      facilita: [ true, true, true, false, false ],
      visite: [ true, true, true, true, false ],
      sicurezza: [ true, false, false, false, false ],
      clienti: [ true, true, true, false, false ],
      firstP: 'Il sito, anche se non è immediato nella comunicazione con il venditore, presenta un’interfaccia semplice. Non viene offerto nessun servizio di intermediazione tra le parti, quindi nessun servizio di spedizione con assicurazione per oggetti quali: elettronica, abbigliamento ecc. Ha comunque un’ampia visibilità per quanto riguarda immobili, posizioni lavorative e corsi di formazione.',
      methods: [
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti',
          ],
        },
      ],
    },
    {
      image: 'assets/vestiaire.png',
      facilita: [ true, true, true, true, false ],
      visite: [ true, true, true, false, false ],
      sicurezza: [ true, true, true, true, true ],
      clienti: [ true, true, true, true, false ],
      firstP: 'Sito intuitivo e facile da navigare; si tratta di una community dove si vendono capi di abbigliamento e accessori di alta moda usati. Il sito tutela ulteriormente il compratore offrendo un servizio a pagamento di autenticazione del prodotto.',
      pros: [
        'Team di esperti che offre un servizio a pagamento (15 Euro) per l’autenticazione del prodotto',
        'Presenza di spedizione assicurata sui prodotti acquistati (con importo che dipende dallo stato dal quale viene acquistato)',
        'Possibilità di reso compresa nel servizio',
        'Possibilità di acquisto a rate',
        'Servizio clienti disponibile tramite chat oltre che al classico tramite mail',
      ],
      cons: [
        'Commissioni di vendita',
      ],
      methods: [
        {
          title: 'Metodo pagamento coperto accettati dal sito (anche a rate, da 3 a 12):',
          subItems: [
            'Carta di credito/debito',
            'PayPal',
            'Apple Pay o Google Pay',
            'PostePay',
            'American Express',
            'CartaSì',
            'Pagantis',
          ],
        },
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti',
          ],
        },
      ],
    },
    {
      image: 'assets/svuotaly.png',
      facilita: [ true, true, true, true, false ],
      visite: [ true, false, false, false, false ],
      sicurezza: [ true, false, false, false, false ],
      clienti: [ true, true, false, false, false ],
      firstP: 'Il sito ha un’interfaccia intuitiva che è semplice da utilizzare. I prodotti venduti principalmente sono capi di abbigliamento e accessori. Il sito offre anche un servizio di consulenza di immagine a pagamento, tramite videochiamata o servizio a domicilio. Svuotaly funge solamente da intermediario tra le parti e non interviene in alcun modo nella fase di compravendita.',
      methods: [
        {
          title: 'Accordandosi di persona: (le parti si assumono i rischi di gestire l’operazione in autonomia)',
          subItems: [
            'Paypal Beni e Servizi, con costi di commissione simili alla protezione acquisti Vinted (il servizio Familiari e Amici e sconsigliato)',
            'Di persona, visto e piaciuto (a discrezione delle due parti',
          ],
        },
      ],
    },
  ];

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.calculateCO2();
  }

  calculateCO2(): void {
    const oneDay = 24 * 60 * 60 * 1000;

    const today = new Date();
    const diffDays = Math.round(Math.abs(((today as any) - (this.startingDate as any)) / oneDay));

    const baseNumber = this.averageCO2 * (diffDays + 1);

    console.log(diffDays);

    this.co2 = baseNumber.toFixed(2);
    this.pasta = (baseNumber * 1.53).toFixed(2);
    this.bottles = (baseNumber * 1.53 * 1.72).toFixed(2);
  }
}
