<div class="p-5 cards-container" *ngIf="!loading">
  <ng-container>
    <div class="filters">
      <div class="checkbox-filter">
        <input type="checkbox" [ngModel]="exactValue" (change)="toggleExactValue()" />
        <label>Visualizza solo articoli contenenti la parola esatta</label>
      </div>
      <ng-container *ngIf="filterStoreOptions && filterStoreOptions.length > 0">
        <div class="checkbox-filter" *ngFor="let storeOption of filterStoreOptions">
          <input (change)="onStoreFilterChange(storeOption.label)" type="checkbox" [checked]="storeOption.checked" />
          <label>{{storeOption.label | titlecase}}</label>
        </div>
      </ng-container>
    </div>

    <div class="filters">
      <div class="price-filter">
        Prezzo minimo: <input *ngIf="maxRange" [formControl]="minPriceRange" type="range" min="0" [max]="maxRange"/> {{minPriceRange.value}}
        Prezzo massimo: <input *ngIf="maxRange" [formControl]="priceRange" type="range" min="0" [max]="maxRange"/> {{priceRange.value}}
      </div>
    </div>

    <div class="number-of-results" *ngIf="items">
      {{items.length}} risultati per "{{searchValue}}" con i filtri applicati
    </div>

    <div class="card mb-5 product-card"
      (click)="openExternal(item.link)"
      *ngFor="let item of items">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-128x128">
              <img src="{{item.picture}}"
                alt="no-image">
            </figure>
          </div>
          <div class="media-content">
            <p class="subtitle is-4">{{item.title}}</p>
            <p class="title is-5">
              <ng-container *ngIf="item.price === 'Prezzo sconosciuto'">
                {{item.price}}
              </ng-container>
              <ng-container *ngIf="item.price !== 'Prezzo sconosciuto'">
                {{item.price | number: '1.0-2' : 'it' }}€
              </ng-container>
          </div>
          <div class="media-right">
            <img *ngIf="item.source !== 'SUBITO'"
              src="assets/{{item.source | lowercase}}.png"/>
            <img *ngIf="item.source === 'SUBITO'"
              src="assets/{{item.source | lowercase}}.svg"/>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="empty" *ngIf="!items || items.length === 0">
    {{error ? 'Contattare assistenza tecnica, errore: ' + error : 'Nessun elemento trovato con questi criteri di ricerca'}}
  </div>
</div>

<ng-container *ngIf="loading">
  <div class="loading-container">
    <div *ngIf="loadingText" class="loading-text animate__animated animate__bounceIn">{{loadingText}}</div>
    <span class="loader"></span>
  </div>
</ng-container>
