import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { defer } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: [ './search.component.scss' ],
})
export class SearchComponent implements OnInit {
  baseItems: Item[];
  items: Item[];
  loading;
  error;
  filterStoreOptions: { label: string, checked: boolean }[] = [];
  exactValue = false;
  searchValue: string;
  maxRange: number;
  priceRange = new FormControl();
  minPriceRange = new FormControl();


  loadingTextOptions = [
    'Non ti arrendere, stai per fare un affare!',
    'Ci sono articoli usati praticamente nuovi, basta solo sapere dove cercare!',
    'Non ti accontentare del primo prezzo, prova a contrattare!',
    'Sii specifico nella ricerca e troverai quello che ti serve!',
    'Ti aiutiamo noi ad annusare l\'affare',
    'Non tirare troppo il prezzo! La corda a volte si spezza...',
    'Paga sempre in sicurezza, evita di rimanere scottato!',
    'Seguici anche su Instagram per ricevere offerte vantaggiose trovate da noi!',
    'Non ti arrendere, quello che cerchi ti aspetta!',
  ];

  loadingText: string;

  constructor(private route: ActivatedRoute, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((p) => {
      this.searchValue = p.q;

      this.loadingText = this.loadingTextOptions[Math.floor(Math.random() * this.loadingTextOptions.length)];
      this.loading = true;
      this.error = null;
      this.items = null;
      this.baseItems = null;
      this.filterStoreOptions = null;
      this.maxRange = null;
      this.exactValue = false;

      const interval = setInterval(() => {
        this.loadingText = null;

        setTimeout(() => {
          this.loadingText = this.loadingTextOptions[Math.floor(Math.random() * this.loadingTextOptions.length)];
        });
      }, 10000);

      this.priceRange.valueChanges.pipe(
        debounceTime(300),
        tap((value) => {
          this.onStoreFilterChange();
        }),
      ).subscribe();

      this.minPriceRange.valueChanges.pipe(
        debounceTime(300),
        tap((value) => {
          this.onStoreFilterChange();
        }),
      ).subscribe();

      this.http.get(`https://hannusato-express.onrender.com/crawl?term=${p.q}`).subscribe((res: Item[]) => {
        let storeOptions = res.map(x => x.source);
        storeOptions = storeOptions.filter((x, i) => storeOptions.indexOf(x) === i);
        this.filterStoreOptions = storeOptions.map(x => ({ label: x, checked: true }));

        this.baseItems = res.map((item) => {
          if (!item.price) {
            item.price = 'Prezzo sconosciuto';
          } else {
            if (item.source === 'SUBITO') {
              item.price = item.price.replace('.', '');
            }

            if (isNaN(item.price)) {
              item.price = item.price.toString().replace(',', '.');
              item.price = item.price.toString().replace('Spedizione disponibile', '').trim();
              item.price = item.price.toString().replace('Venduto', '').trim();
            }

            item.price = isNaN(item.price) ? 'Prezzo sconosciuto' : item.price > 5000 ? 'Prezzo sconosciuto' : item.price;
          }

          return item;
        });

        let withPrice = this.baseItems.filter(i => i.price !== 'Prezzo sconosciuto');
        const withoutPrice = this.baseItems.filter(i => i.price === 'Prezzo sconosciuto');

        this.maxRange = Math.max(...withPrice.map(x => x.price));
        this.priceRange.setValue(this.maxRange);
        this.minPriceRange.setValue(0);

        withPrice = withPrice.sort((a, b) => a.price - b.price);
        this.baseItems = [ ...withPrice, ...withoutPrice ];

        this.items = this.baseItems;
        this.loading = false;
        this.loadingText = null;
        clearInterval(interval);
      }, err => {
        this.loading = false;
        this.loadingText = null;
        clearInterval(interval);
        this.error = err.message;
      });
    });
  }

  openExternal(link: string): void {
    window.open(link);
  }

  onStoreFilterChange(storeName: string = null): void {
    if (storeName) {
      const store = this.filterStoreOptions.find(x => x.label === storeName);

      if (store) {
        store.checked = !store.checked;
      }
    }

    const activeStores = this.filterStoreOptions.filter(x => x.checked).map(x => x.label);
    this.items = this.baseItems.filter(x => activeStores.includes(x.source));

    if (this.exactValue) {
      this.items = this.items.filter(x => x.title.toLowerCase().includes(this.searchValue.toLowerCase()));
    }

    if (this.priceRange.value) {
      this.items = this.items.filter(x => x.price === 'Prezzo sconosciuto' || parseFloat(x.price) <= this.priceRange.value);
    }

    if (this.minPriceRange.value) {
      this.items = this.items.filter(x => x.price === 'Prezzo sconosciuto' || parseFloat(x.price) >= this.minPriceRange.value);
    }
  }

  toggleExactValue(): void {
    this.exactValue = !this.exactValue;
    this.onStoreFilterChange();
  }
}

export interface Item {
  title: string;
  link: string;
  price: any;
  picture: string;
  source: string;
}
