<div class="top-container">
  <img class="logo-topbar mt-5" routerLink="/home"
    src="assets/logo.png"/>

  <div class="slogan mt-3 mb-2 has-text-white">
    Loro l'hanno usato, Noi annusiamo l'affare
  </div>

  <div class="has-text-white mb-5 has-text-weight-bold has-text-centered is-small mt-4 small-text">
    Stai cercando qualcosa? Vuoi Risparmiare? Qui puoi trovare i prodotti offerti dai più grandi siti dell'usato in ordine di prezzo.
  </div>

  <div class="search-container">
    <input class="input mb-4"
      (keydown.enter)="search()"
      [formControl]="searchControl"
      type="text"
      placeholder="Ricerca">

    <button class="button is-white"
      [disabled]="!searchControl.value"
      (click)="search()">Cerca
    </button>
  </div>
</div>

<router-outlet></router-outlet>
